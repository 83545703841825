import { Link } from "gatsby";
import React, { Fragment } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const CallToAction = ({ index }) => {
	let body = "";
	const breakpoints = useBreakpoint();

	switch (index) {
		case 0:
			body = (
				<Fragment>
					Une pédagogie à destination des acteurs de l'apprentissage :
					{breakpoints.smallUp ? <br></br> : ` `}
					enseignants, orthophonistes, coachs scolaires, etc.
				</Fragment>
			);
			break;
		case 1:
			body = (
				<Fragment>
					Des stratégies d'apprentissage pour aider les enfants
					{breakpoints.smallUp ? <br></br> : ` `}à partir de 3 ans, les
					collégiens, lycéens jusqu'aux étudiants
				</Fragment>
			);
			break;
		case 2:
			body = (
				<Fragment>
					Une pédagogie engagée dans la réussite de tous les élèves,
					{breakpoints.smallUp ? <br></br> : ` `}quelles que soient leurs
					difficultés
				</Fragment>
			);
			break;
	}

	return (
		<div className={`call-to-action`}>
			<h1 className={`call-to-action__title`}>
				Apprendre à apprendre<br></br>avec la PNL
			</h1>
			<h2 className={`call-to-action__body`}>{body}</h2>
			{breakpoints.largeUp ? (
				<Link to="/la-pedagogie-pnl" className={`link_default`}>
					<button className={`btn btn--green call-to-action__button`}>
						En savoir plus
					</button>
				</Link>
			) : (
				``
			)}
		</div>
	);
};

export default CallToAction;
