import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import SlideShow from "../components/slide-show/slide-show";
import KnowMore from "../components/know-more/know-more";

const query = graphql`
	query {
		strapiPageAccueil {
			title
			body
			seo {
				metaDescription
				metaTitle
			}
		}
	}
`;

const IndexPage = () => {
	const data = useStaticQuery(query);
	const page = data.strapiPageAccueil;

	return (
		<Layout seo={page.seo}>
			<div className={`index-page`}>
				<SlideShow></SlideShow>
				<KnowMore page={page}></KnowMore>
			</div>
		</Layout>
	);
};

export default IndexPage;
