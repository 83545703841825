import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/effect-fade/effect-fade.scss";
require("swiper/swiper-bundle.css");
import CallToAction from "./call-to-action";

SwiperCore.use([Autoplay, EffectFade]);

const SlideShow = () => {
	const data = useStaticQuery(graphql`
		query {
			slidePictures: allFile(
				filter: { sourceInstanceName: { eq: "slide" } }
				sort: { fields: name }
			) {
				edges {
					node {
						childImageSharp {
							fluid(srcSetBreakpoints: [480, 960, 1440, 1920], maxWidth: 1920) {
								...GatsbyImageSharpFluid_withWebp_noBase64
							}
						}
					}
				}
			}
		}
	`);

	return (
		<div className={`slide-show`}>
			<Swiper
				spaceBetween={0}
				slidesPerView={1}
				effect="fade"
				fadeEffect={{ crossFade: true }}
				speed={2000}
				autoplay={{ delay: 5000 }}
			>
				{data.slidePictures.edges.map((image, index) => (
					<SwiperSlide key={image.node.childImageSharp.fluid.src}>
						<div className={`slide slide--${index + 1}`}>
							<Img fluid={image.node.childImageSharp.fluid} />
						</div>
						<CallToAction index={index}></CallToAction>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default SlideShow;
