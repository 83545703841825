import { Link, useStaticQuery } from "gatsby";
import React from "react";
import Img from "gatsby-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import parse from "html-react-parser";
import { graphql } from "gatsby";

const KnowMore = ({ page }) => {
	const data = useStaticQuery(graphql`
		query {
			girlPicture: file(relativePath: { eq: "girl.png" }) {
				childImageSharp {
					fluid(srcSetBreakpoints: [480], maxWidth: 480) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	`);
	const breakpoints = useBreakpoint();

	return (
		<div className={`know-more`}>
			<div className={`container`}>
				<div className={`grid grid-cols-1 sm:grid sm:grid-cols-2`}>
					<div className={`know-more__presentation`}>
						<h3 className={`know-more__title`}>{page.title}</h3>
						<div className={`know-more__body`}>
							{parse(page.body)}
							<Link to="/la-pedagogie-pnl" className={`link_default`}>
								<button
									className={`btn btn--white know-more__button btn--focus-purple`}
								>
									En savoir plus
								</button>
							</Link>
						</div>
					</div>

					{breakpoints.smallUp ? (
						<div className={`know-more__picture`}>
							<Img fluid={data.girlPicture.childImageSharp.fluid} />
						</div>
					) : (
						``
					)}
				</div>
			</div>
		</div>
	);
};

export default KnowMore;
